.contCardLandingAD
    border: 1px solid #ccc
    border-radius: 15px
    max-width: 1000px
    margin: 40px auto
    @media only screen and (max-width: 768px)
        margin: 40px 10px
    .cardLandingAD
        padding: 20px
        border-bottom: 1px solid #ccc
        &:last-child
            border-bottom: none
        &.active
            .cardLandingDescripAD
                display: block
    .cardLandingTitleAD
        font-size: 1.3rem
        font-weight: bold
        text-align: left
        position: relative
        cursor: pointer
        width: calc(100% - 40px)
        &:after
            content: ""
            position: absolute
            top: calc(50% - 12px)
            width: 13px
            height: 13px
            display: block
            border-top: 3px solid #666
            border-right: 3px solid #666
            transform: rotate(135deg)
            right: -40px
    .cardLandingDescripAD
        display: none
        padding-top: 15px
        text-align: left
        p
            font-size: 1.1rem
            line-height: 1.2
    .textRed
        color: var(--color-rojo-pale)

    .dotedList
        margin: 20px 0
        li
            line-height: 1.5rem
            list-style: none
            padding: 5px
            padding-left: 25px 
            list-style-position: inside
            &:before
                content: '•'
                color: black
                font-weight: bold
                display: inline-block
                width: 1em
                margin-left: -1em
    .linkLandingAD,
    .linkLandingAD1
        color: var(--color-rojo-pale)
        font-weight: bold
        text-decoration: none
        margin-top: 20px
        display: inline-block
        @media only screen and (max-width: 768px)
            display: block
        &.dark
            color: black
        &:hover
            text-decoration: underline
    .linkLandingAD1
        margin-top: inherit
        display: contents
        color: inherit
        font-weight: normal
        &:hover
            text-decoration: none

    .miniTalbe
        border-radius: 15px
        border: 1px solid #ccc
        line-height: 1.5rem
        margin-top: 20px
        li
            padding: 15px 10px
            display: flex
            flex-direaction: row
            align-items: center
            justify-content: flex-start
            border-bottom: 1px solid #ccc
            &:last-child
                border-bottom: none
        .contTitleMiniT
            width: 150px
            min-width: 150px
            padding-right: 50px
            box-sizing: content-box
            font-weight: bold
            text-align: left
            font-size: 0.9rem
            @media only screen and (max-width: 768px)
                width: 90px
                min-width: 90px