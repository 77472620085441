header
    background: white
    box-sizing: border-box
    min-height: 45px
    display: flex
    flex-direction: row
    align-items: center
    position: sticky
    top: 0
    width: 100%
    z-index: 4
    flex-wrap: wrap
    position: relative
    .desktopView &,
    .fullHeader &
        height: 110px
        flex-direction: column
        padding: 0
    &.simple
        .desktopView &
            height: 60px
    &.hidden
        height: 0
        padding: 0
        display: none
    .is-dark-mode &
        background: var(--color-background-dm)
        color: white
    p
        margin: 0

    .logoClaroshop
        width: 127px
        height: 30px
        margin: 0 auto 0 10px
        display: block
        background-image: url(/img/claroshop.svg)
        background-repeat: no-repeat
        background-position: center
        .is-dark-mode &
            background-image: url(/img/claroshop_DM.svg)
        .desktopView &,
        .fullHeader &
            width: 235px
            min-width: 180px
            min-height: 45px
            margin: 0 10px 0 0
            background-image: url(/img/claroshop.svg)
            @media only screen and (max-width: 780px)
                margin: 5px auto 0
            .is-dark-mode &
                background-image: url(/img/claroshop_DM.svg)
    &.simple
        .logoClaroshop
            .desktopView &
                width: 175px
                height: 30px

    .menuLateral,
    .searchIcon
        box-sizing: border-box
        padding: 10px
        display: block
        cursor: pointer
        width: 40px
        height: 40px
        background-repeat: no-repeat
        background-position: center
        margin: 0px 5px
        .desktopView &
            margin: 0px 15px

    .menuLateral
        background-image: url(/img/menu.png)
        background-size: 30px
        .is-dark-mode &
            filter: invert(1)
    .searchIcon
        background-image: url(/img/search.svg)
        background-size: 20px 20px
        opacity: 0.7
        .is-dark-mode &
            filter: invert(1)
    .icoMiCuenta
        &.navBar
            margin: -5px 5px 0
            position: relative
            text-decoration: none
            color: inherit
            width: 23px
            height: 23px
            font-size: 10px
            span
                color: black
                position: absolute
                bottom: -15px
                transform: translate(-50%, -50%)
                width: 40px

    .headerSup,
    .headerInf
        width: 100%
        .container
            display: flex
            flex-direction: row
            align-items: center
            @media only screen and (max-width: 990px)
                padding: 0
    .headerSup
        .container
            height: 68px
            @media only screen and (max-width: 780px)
                flex-direction: column
                height: 100px
    .headerInf
        box-sizing: content-box
        background: #333
        font-weight: 500
        height: 42px
        position: relative
        top: 0
        @media only screen and (max-width: 780px)
            display: none
        .barHeaderCP
            width: 100%
            max-width: 310px
            border: none
            margin-bottom: 0
            background: transparent
            color: white
            @media only screen and (max-width: 1100px)
                max-width: 250px
            .titleCP
                width: 100%
                padding: 13px 30px 13px 40px
                &:before
                    top: 12px
                    filter: invert(1)
                &:after
                    top: 14px
                    right: 15px
    .headerMobile
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        width: inherit
        margin-top: -7px
        padding: 15px 5px 0
                
    .moduleSearchAutocomplete
        width: 100%
        margin: auto
        max-width: 90%
        .desktopView &,
        .fullHeader &
            max-width: 60%
            @media only screen and (max-width: 780px)
                max-width: calc(100% - 20px)

    .promoHeader
        width: calc( 93% - 175px)
        margin-left: 2px
        font-size: 0.9rem
        ul
            display: flex !important
            flex-direction: row
            justify-content: flex-start
        li
            display: flex !important
            align-items: center
            justify-content: center
            text-align: center
            color: white
        a
            box-sizing: border-box
            color: inherit
            text-decoration: none
            display: flex !important
            align-items: center
            justify-content: center
            padding: 0 10px
            width: 100%
            height: 42px
            color: white
            font-weight: 500

    .fontYellow
        color: #f8cf04 !important
    .bgRed
        background: var(--color-rojo-pale)
        .is-dark-mode &
            background: rgba(#C00,0.5)
    .bgBlue
        background: var(--color-azul-telmex)
        .is-dark-mode &
            background: rgba(#1485c1,0.5)
    .bgGreen
        background: #028805
        .is-dark-mode &
            background: rgba(#0DC511,0.5)

    .categoryMenuHeader
        width: 175px
        max-width: 175px
        min-width: 175px
        margin-left: 0
        @media only screen and (max-width: 990px)
            width: 145px
            max-width: 145px
            min-width: 145px
    .productosNewCat
        box-sizing: border-box
        padding: 0 8px
        width: 100%
        position: relative
        font-size: 0.9rem
        font-weight: 400
        a
            box-sizing: border-box
            color: inherit
            text-decoration: none
            display: flex !important
            align-items: center
            justify-content: center
            width: 100%
            font-weight: 500
        > a
            padding: 13px
            color: white

    .menuTiendas
        box-sizing: border-box
        width: 40%
        font-size: 0.9rem
        font-weight: 400
        @media only screen and (max-width: 830px)
            display: none
        ul
            display: flex
            flex-direction: row
            justify-content: space-between
        li
            box-sizing: border-box
            display: flex
            align-items: center
            justify-content: space-between
            color: white
            height: 42px
            min-width: 96px
            text-align: center
            width: 50%
            position: relative
            &:first-child
                &:after
                    content: ""
                    width: 1px
                    height: 20px
                    top: 10px
                    position: absolute
                    right: -1px
                    background: white
        a
            color: inherit
            text-decoration: none
            display: flex
            align-items: center
            justify-content: center
            width: 100%
            height: 100%
            font-weight: 500

    .menuDesk
        display: flex
        flex-direction: row
        justify-content: space-between
        color: #555
        width: 100%
        min-width: 400px
        max-width: 400px
        z-index: 120
        width: 40%
        a
            color: inherit
            text-decoration: none
        @media only screen and (max-width: 990px)
            min-width: 260px
            max-width: 260px
            margin-left: 10px
        @media only screen and (max-width: 780px)
            position: fixed
            max-width: 100%
            min-width: 100%
            bottom: 0
            padding: 20px
            box-sizing: border-box
            z-index: 10
            background: white
            border-top: 1px solid var(--color-gris-borde)
            left: 0
            right: 0
            margin-left: 0
            .is-dark-mode &
                background: #1a1a1a
                border-top: 1px solid #292f35

    .textReference
        font-size: 12px
        width: 65px
        display: none
        text-align: left
        .is-dark-mode &
            height: 40px
        .desktopView &,
        .fullHeader &
            display: block
        .smallText
            font-size: 8px
            display: block
        @media only screen and (max-width: 990px)
            text-align: center
            margin: auto
            font-size: 9px
            width: 65px

    .cartIcon
        .desktopView &,
        .fullHeader &
            padding-left: 30px
            width: 100px
            box-sizing: border-box
            background-position: left
            .textReference
                width: 45px
            .contCart
                border-radius: 5px
            @media only screen and (max-width: 990px)
                .contCart
                    left: 27px
                    top: -1px
            @media only screen and (max-width: 780px)
                .contCart
                    left: 45px

    .heartIcon
        .desktopView &,
        .fullHeader &
            padding-left: 30px
            width: 100px
            box-sizing: border-box
            background-position: left

    .icoMiCuenta
        background-image: url(/img/usuario-micuenta.svg)
        width: 25px
        height: 25px
        display: inline-block
        background-size: contain
        background-repeat: no-repeat
        background-position: left center
        margin-bottom: -7px
        .is-dark-mode &
            filter: invert(1)
        .desktopView &,
        .fullHeader &
            padding-left: 30px
            width: 100px
            box-sizing: border-box
            background-position: left

    .icoPedidos
        background-image: url(/img/iconMisPedidos.svg)
        width: 25px
        height: 25px
        display: inline-block
        background-size: contain
        background-repeat: no-repeat
        background-position: left center
        margin-bottom: -7px
        .is-dark-mode &
            filter: invert(1)
        .desktopView &,
        .fullHeader &
            padding-left: 30px
            width: 100px
            box-sizing: border-box
            background-position: left

    .cartIcon,
    .heartIcon,
    .icoMiCuenta,
    .icoPedidos
        .desktopView &,
        .fullHeader &
            @media only screen and (max-width: 990px)
                background-position: top center
                padding-left: 0
                padding-top: 30px
                width: 100px
                margin: -15px 0 0
            @media only screen and (max-width: 780px)
                background-position: top center
                padding-left: 0
                padding-top: 30px
                width: 100px
                margin: -15px 0 0
