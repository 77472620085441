@import './abstracts'

$versionFCS: '1.0.0'
@font-face
	font-family: 'icoClaroShop'
	src: url('/resources/fonts/icoClaroShop.eot?v='+$versionFCS)
	src: url('/resources/fonts/icoClaroShop.eot?v='+$versionFCS+'#iefix') format('embedded-opentype'), url('/resources/fonts/icoClaroShop.ttf?v='+$versionFCS) format('truetype'), url('/resources/fonts/icoClaroShop.woff?v='+$versionFCS) format('woff'), url('/resources/fonts/icoClaroShop.svg?v='+$versionFCS+'#icoClaroShop') format('svg')
	font-weight: normal
	font-style: normal

[class^="icoShop-"], [class*="icoShop-"]
	//-use !important to prevent issues with browser extensions that change fonts
	font-family: 'icoClaroShop'
	speak: none
	font-style: normal
	font-weight: normal
	font-variant: normal
	text-transform: none
	line-height: 1

	//- Better Font Rendering
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

=shopIcono($name,$value)
	.icoShop-#{$name}
		&:before
			content: "#{$value}"

+shopIcono('ninos','\e900')
+shopIcono('libros','\e901')
+shopIcono('mejoraHogar','\e902')
+shopIcono('muebles','\e903')
+shopIcono('ninosBebes','\e904')
+shopIcono('help','\e905')
+shopIcono('saludBelleza','\e906')
+shopIcono('tecElectro','\e907')
+shopIcono('videojuegos','\e908')
+shopIcono('deportes','\e909')
+shopIcono('el','\e90a')
+shopIcono('ella','\e90b')
+shopIcono('entretenimiento','\e90c')
+shopIcono('hogar','\e90d')
+shopIcono('logoBanamex','\e90e')
+shopIcono('logoClaro','\e90f')

+shopIcono('logoShop','\e910')
+shopIcono('tresXdos','\e911')
+shopIcono('logoClaroShop','\e912')
+shopIcono('factura','\e913')
+shopIcono('favorito','\e914')
+shopIcono('mensualidadFija','\e915')
+shopIcono('pagoEfectivo','\e916')
+shopIcono('share','\e917')
+shopIcono('handPointer','\e918')
+shopIcono('tallaColor','\e919')
+shopIcono('search','\e91a')
+shopIcono('userLogin','\e91b')
+shopIcono('carrito','\e91c')
+shopIcono('vistaRapida','\e91d')
+shopIcono('lineHeart','\e91e')
+shopIcono('heart','\e91f')

+shopIcono('lineShare','\e920')
+shopIcono('sears','\e921')
+shopIcono('telmex','\e922')
+shopIcono('sanborns','\e923')
+shopIcono('info','\e924')
+shopIcono('checked','\e925')
+shopIcono('venderProd','\e926')
+shopIcono('realizarEnvio','\e927')
+shopIcono('recibirPago','\e928')
+shopIcono('registrar','\e929')
+shopIcono('crearTienda','\e92a')
+shopIcono('lineVistaRapida','\e92b')
+shopIcono('lineCart','\e92c')
+shopIcono('solidCart','\e92d')
+shopIcono('linePlus','\e92e')
+shopIcono('plus','\e92f')

+shopIcono('farmacia','\e930')
+shopIcono('optica','\e931')
+shopIcono('maquillaje','\e932')
+shopIcono('celulares_y_telefonia','\e933')
+shopIcono('joyeria_relojes','\e934')
+shopIcono('ropa_calzado_accesorios','\e935')
+shopIcono('otras_categorias','\e936')
+shopIcono('animales_y_mascotas','\e937')
+shopIcono('icoPin','\e938')
+shopIcono('icoPago','\e939')
+shopIcono('icoMensaje','\e93a')
+shopIcono('icoFactura','\e93b')
+shopIcono('icoEstrella','\e93c')
+shopIcono('icoCarrito','\e93d')
+shopIcono('icoCamion','\e93e')
+shopIcono('icoCarritoLine','\e93f')



+shopIcono('vistaLista','\e940')
+shopIcono('vistaThumb','\e941')
+shopIcono('claroshopCom','\e942')
+shopIcono('bebidas','\e943')
+shopIcono('automotriz_y_motocicletas','\e944')
+shopIcono('envioGratis','\e945')
+shopIcono('instrumentos_musicales','\e946')
+shopIcono('oficina_y_papeleria','\e947')
