.ico-celulares-y-telefonia,
.ico-ropa-calzado-y-accesorios,
.ico-joyeria-y-relojes,
.ico-otras-categorias,
.ico-tecnologia-y-electronica, .ico-electronica-y-tecnologia,
.ico-hogar, .ico-hogar-y-jardin,
.ico-videojuegos,
.ico-libros-y-revistas, .ico-libros-revistas-y-comics,
.ico-ninos-y-bebes, .ico-mama-y-bebe,
.ico-juguetes, .ico-juegos-y-juguetes,
.ico-deportes, .ico-deportes-y-ocio,
.ico-ferreteria-y-autos,
.ico-entretenimiento, .ico-peliculas-series-de-tv-y-musica,
.ico-salud-y-belleza, .ico-salud-belleza-y-cuidado-personal,
.ico-animales-y-mascotas,
.ico-farmacia,
.ico-bebidas,
.ico-supermercado
	display: table !important
	text-align: left
	padding: initial
	position: relative
	&:before
		content: ""
		background-size: contain
		background-repeat: no-repeat
		width: 22px
		height: 22px
		display: inline-block
		position: absolute
		color: back
		top: 0px
		left: -20px
		.is-dark-mode &
			filter: invert(1)
.ico-celulares-y-telefonia
	&:before
		background-image: url(/img/ico-celulares_y_telefonia.svg)
		left: -26px
		top: -4px
.ico-ropa-calzado-y-accesorios
	&:before
		background-image: url(/img/ico-ropa_calzado_accesorios.svg)
		width: 19px
		height: 19px
		left: -24px
		top: 2px
.ico-joyeria-y-relojes
	&:before
		background-image: url(/img/ico-joyeria_relojes.svg)
		left: -25px
		top: -6px
.ico-otras-categorias
	&:before
		background-image: url(/img/ico-otras_categorias.svg)
		width: 17px
		height: 17px
		left: -24px
		top: -3px
.ico-tecnologia-y-electronica,
.ico-electronica-y-tecnologia
	&:before
		background-image: url(/img/ico-tecElectro.svg)
		left: -27px
		top: 2px
.ico-hogar, 
.ico-hogar-y-jardin
	&:before
		background-image: url(/img/ico-hogar.svg)
		width: 18px
		height: 18px
		left: -23px
		top: -4px
.ico-videojuegos
	&:before
		background-image: url(/img/ico-videojuegos.svg)
		left: -24px
		top: -6px
.ico-libros-y-revistas,
.ico-libros-revistas-y-comics
	&:before
		background-image: url(/img/ico-libros.svg)
		left: -24px
		top: -3px
.ico-salud-y-belleza,
.ico-salud-belleza-y-cuidado-personal
	&:before
		background-image: url(/img/ico-saludBelleza.svg)
		width: 19px
		height: 19px
		left: -25px
		top: 2px
.ico-ninos-y-bebes,
.ico-mama-y-bebe
	&:before
		background-image: url(/img/ico-ninosBebes.svg)
		top: -5px
		left: -26px
.ico-juguetes,
.ico-juegos-y-juguetes
	&:before
		background-image: url(/img/ico-ninos.svg)
		left: -23px
		top: -5px
.ico-deportes,
.ico-deportes-y-ocio
	&:before
		background-image: url(/img/ico-deportes.svg)
		left: -24px
		top: -3px
.ico-ferreteria-y-autos
	&:before
		background-image: url(/img/ico-mejoraHogar.svg)
		width: 26px
		height: 26px
		left: -23px
		top: -4px	
.ico-entretenimiento,
.ico-peliculas-series-de-tv-y-musica
	&:before
		background-image: url(/img/ico-entretenimiento.svg)
		left: -24px
		top: -3px
.ico-animales-y-mascotas
	&:before
		background-image: url(/img/ico-animalesMascotas.svg)
		width: 18px
		height: 18px
		left: -22px
		top: -3px
.ico-farmacia
	&:before
		background-image: url(/img/ico-farmacia.svg)
		width: 16px
		height: 16px
		left: -22px
		top: -3px
.ico-bebidas
	&:before
		background-image: url(/img/ico-Bebidas.svg)
		width: 20px
		height: 20px
		left: -25px
		top: -7px
.ico-supermercado
	&:before
		background-image: url(https://resources.claroshop.com/medios-plazavip/swift/v1/claroshop/claroshop/categorias/ico-supermercado-ico.png)
		width: 22px
		height: 22px
		left: -25px
		top: -5px